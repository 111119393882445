import { formatNameToURL, formatWeight } from "./formatters";
import _ from "lodash";
import i18n from "../translations/i18n";
import moment from "moment";
import {
  getProductCollection,
  isCollectionVisibleForGroup,
} from "./collections";
import {
  PRODUCT_COLORS,
  PRODUCT_DIAMETERS,
  PRODUCT_HEIGHTS,
} from "../constants/ProductConstants";
import { CATEGORY_SALE } from "../constants/CategoryConstants";

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : null;
};

// get product discount price with default
export const getDiscountPriceWithDefault = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : price;
};

export const getPaymentAndDiscountPriceForGroup = (
  product,
  groupId,
  groupSettingsUserGroup,
  selectedSalesGroup,
) => {
  if (!!product) {
    const productAlternatives = product.groupAlternatives;
    const notFoundProductAlternativesForUser =
      !productAlternatives ||
      (!!productAlternatives &&
        !productAlternatives.find((item) => item.groupId === groupId)) ||
      (!!productAlternatives &&
        !productAlternatives.find((item) => item.groupId === groupId).price);
    if (!!selectedSalesGroup) {
      const percentageChange =
        selectedSalesGroup.pricePercentageModification || 0;
      const newOriginalPrice = getPercentageChangePrice(
        product.price,
        percentageChange,
      );
      return {
        discounted: getDiscountPrice(newOriginalPrice, product.discount),
        original: newOriginalPrice,
      };
    } else if (
      notFoundProductAlternativesForUser &&
      (!groupSettingsUserGroup ||
        (!!groupSettingsUserGroup &&
          !groupSettingsUserGroup.pricePercentageModification))
    ) {
      return {
        discounted: getDiscountPrice(product.price, product.discount),
        original: product.price,
      };
    } else if (!notFoundProductAlternativesForUser) {
      let groupAlternatives = productAlternatives.find(
        (item) => item.groupId === groupId,
      );
      return {
        discounted: getDiscountPrice(groupAlternatives.price, product.discount),
        original: groupAlternatives.price,
      };
    } else if (
      !!product.category &&
      product.category.includes(CATEGORY_SALE) &&
      (!groupSettingsUserGroup ||
        groupSettingsUserGroup.pricePercentageModification !== -100)
    ) {
      return {
        discounted: getDiscountPrice(product.price, product.discount),
        original: product.price,
      };
    } else {
      const percentageChange =
        groupSettingsUserGroup.pricePercentageModification || 0;
      const newOriginalPrice = getPercentageChangePrice(
        product.price,
        percentageChange,
      );
      return {
        discounted: getDiscountPrice(newOriginalPrice, product.discount),
        original: newOriginalPrice,
      };
    }
  } else return 0;
};

export const getPaymentPriceForGroup = (
  product,
  groupId,
  groupSettingsUserGroup,
  selectedSalesGroup,
) => {
  const productAlternatives = product.groupAlternatives;
  const notFoundProductAlternativesForUser =
    !productAlternatives ||
    (!!productAlternatives &&
      !productAlternatives.find((item) => item.groupId === groupId)) ||
    (!!productAlternatives &&
      !productAlternatives.find((item) => item.groupId === groupId).price);
  if (!!selectedSalesGroup) {
    const percentageChange =
      selectedSalesGroup.pricePercentageModification || 0;
    const newOriginalPrice = getPercentageChangePrice(
      product.price,
      percentageChange,
    );
    return getDiscountPriceWithDefault(newOriginalPrice, product.discount);
  } else if (
    notFoundProductAlternativesForUser &&
    (!groupSettingsUserGroup ||
      (!!groupSettingsUserGroup &&
        !groupSettingsUserGroup.pricePercentageModification))
  ) {
    return getDiscountPriceWithDefault(product.price, product.discount);
  } else if (!notFoundProductAlternativesForUser) {
    let groupAlternatives = productAlternatives.find(
      (item) => item.groupId === groupId,
    );
    return getDiscountPriceWithDefault(
      groupAlternatives.price,
      product.discount,
    );
  } else if (!!product.category && product.category.includes(CATEGORY_SALE)) {
    return getDiscountPriceWithDefault(product.price, product.discount);
  } else {
    const percentageChange =
      groupSettingsUserGroup.pricePercentageModification || 0;
    const newOriginalPrice = getPercentageChangePrice(
      product.price,
      percentageChange,
    );
    return getDiscountPriceWithDefault(newOriginalPrice, product.discount);
  }
};

const getPercentageChangePrice = (price, percentage) => {
  const difference = (price / 100) * percentage;
  return price + difference;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product) => {
  let productInCart = cartItems.filter((single) => single.id === product.id)[0];
  if (cartItems.length >= 1 && productInCart) {
    return cartItems.filter((single) => product.id === single.id)[0].quantity;
  } else {
    return 0;
  }
};

export const getProductsByCategory = (products, selectedCategory) => {
  if (!selectedCategory) return products;
  if (selectedCategory.categoryId) {
    return products.filter(
      (product) =>
        product.category === selectedCategory.categoryId ||
        selectedCategory.subcategoryIds?.includes(product.category),
    );
  } else if (selectedCategory.subcategoryId) {
    return products.filter(
      (product) => product.category === selectedCategory.subcategoryId,
    );
  } else {
    return products;
  }
};

//get products based on category
export const getSortedProducts = (products, sortType, sortValue, groupId) => {
  if (products && sortType && sortValue) {
    if (sortType === "category") {
      return products.filter(
        (product) =>
          product.category.filter(
            (single) =>
              formatNameToURL(single.toLowerCase()) === sortValue.toLowerCase(),
          )[0],
      );
    }
    if (sortType === "tag") {
      return products.filter(
        (product) => product.tag.filter((single) => single === sortValue)[0],
      );
    }
    if (sortType === "color") {
      return products.filter(
        (product) =>
          product.variation &&
          product.variation.filter((single) => single.color === sortValue)[0],
      );
    }
    if (sortType === "size") {
      return products.filter(
        (product) =>
          product.variation &&
          product.variation.filter(
            (single) =>
              single.size.filter((single) => single.name === sortValue)[0],
          )[0],
      );
    }
    if (sortType === "filterSort") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts.sort((a, b) => {
          const priceA = getPaymentPriceForGroup(a, groupId);
          const priceB = getPaymentPriceForGroup(b, groupId);
          return priceB - priceA;
        });
      }
      if (sortValue === "priceLowToHigh") {
        return sortProducts.sort((a, b) => {
          const priceA = getPaymentPriceForGroup(a, groupId);
          const priceB = getPaymentPriceForGroup(b, groupId);
          return priceA - priceB;
        });
      }
    }
  }
  return products;
};

export const sortProductsByPopular = (products) => {
  return _.orderBy(products, ({ purchased }) => purchased || 0, ["desc"]);
};

export const sortProductsAlphabetically = (products) => {
  return _.orderBy(products, ({ name }) => name.toLowerCase(), ["asc"]);
};

export const sortProductsByFeatured = (
  products,
  featuredLists,
  featuredType,
) => {
  let currentList =
    !!featuredLists && featuredLists.find((item) => item.type === featuredType);
  if (
    !!currentList &&
    !!currentList.products &&
    currentList.products.length > 0
  ) {
    // Sort products by whether they are included in the featured list or not
    return _.sortBy(products, [
      (item) => {
        let index = _.findIndex(currentList.products, (id) => id === item.id);
        // If product is not found, modify index to be bigger than the featured products because
        // we sort the entire list by ascending order
        if (index === -1) index = index + products.length + 1;
        return index;
      },
    ]);
  } else return products;
};

// get individual element
const getIndividualItemArray = (array) => {
  return array.filter(function (v, i, self) {
    return i === self.indexOf(v);
  });
};

// get individual categories
export const getIndividualCategories = (products) => {
  let productCategories = [];
  products &&
    products.map((product) => {
      return (
        product.category &&
        typeof product.category === "object" &&
        product.category.map((single) => {
          return productCategories.push(single);
        })
      );
    });
  return getIndividualItemArray(productCategories);
};

export const setActiveSort = (e) => {
  const filterButtons = document.querySelectorAll(
    ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button",
  );
  filterButtons.forEach((item) => {
    item.classList.remove("active");
  });
  if (!!e.currentTarget) e.currentTarget.classList.add("active");
};

export const setActiveLayout = (e) => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
  gridSwitchBtn.forEach((item) => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

/**
 * Get variant price if product has variant price, else return product price
 * @param {*} item
 * @returns price
 */
export const getVariantWeightPrice = (item) => {
  return item.variation && item.variation.weight
    ? item.variation.price
    : item.price;
};

/**
 * Show formatted product weight with unit. To display behind product.
 * E.g. Product x (100 gram)
 * @param cartItem cartItem
 * @param t translation
 * @returns formatted weight
 */
export const showProductWeight = (cartItem, t) => {
  return cartItem.variation && cartItem.variation.weight
    ? "(" + formatWeight(cartItem.variation.weight) + " " + t("gram") + ")"
    : "";
};

/**
 * Show formatted product type. To display behind product name.
 * E.g. Product x (marinated)
 * @param product
 * @returns {string|null}
 */
export const showProductType = (product) => {
  return product.type ? "(" + product.type + ")" : null;
};

/**
 * Get product modal text used in My Orders to re-oder a order
 * @param changedProductList
 * @param notAvailableProductList
 * @returns {string}
 */
export const formatProductModalText = (
  changedProductList,
  notAvailableProductList,
) => {
  if (
    changedProductList &&
    changedProductList.length > 0 &&
    notAvailableProductList === null
  ) {
    if (changedProductList.length > 1) {
      return i18n.t("modal.product_price_changed_plural");
    } else {
      return i18n.t("modal.product_price_changed");
    }
  } else if (
    notAvailableProductList &&
    notAvailableProductList.length > 0 &&
    changedProductList === null
  ) {
    if (notAvailableProductList.length > 1) {
      return i18n.t("modal.product_not_available_plural");
    } else {
      return i18n.t("modal.product_not_available");
    }
  } else if (
    changedProductList &&
    changedProductList.length > 0 &&
    notAvailableProductList &&
    notAvailableProductList.length > 0
  ) {
    return i18n.t("modal.product_has_changed_plural");
  }
};

export const searchProducts = ({ query, products, categories }) => {
  const queryLowerCase = query.toLowerCase();
  const categoryId = getCategoryIdFromSearchQuery(queryLowerCase, categories);
  if (!products) return [];
  else
    return products.filter(
      (product) =>
        product.name.toLowerCase().includes(queryLowerCase) ||
        product.code.toLowerCase().includes(queryLowerCase) ||
        product.category === categoryId,
    );
};

const getCategoryIdFromSearchQuery = (query, categories) => {
  if (!categories || categories.length === 0) return null;
  const category = _.find(
    categories,
    (category) =>
      category.name?.toLowerCase().includes(query) ||
      category.nameDE?.toLowerCase().includes(query) ||
      category.nameEN?.toLowerCase().includes(query) ||
      getCategoryIdFromSynonyms(category.synonyms, query),
  );
  if (category) {
    return category.categoryId;
  } else {
    for (let i = 0; i < categories.length; i++) {
      const subcategories = categories[i].subcategories;
      if (subcategories?.length > 0) {
        const subcategory = _.find(
          subcategories,
          (subcategory) =>
            subcategory.name.toLowerCase().includes(query) ||
            subcategory.nameDE.toLowerCase().includes(query) ||
            subcategory.nameEN.toLowerCase().includes(query) ||
            getCategoryIdFromSearchQuery(subcategory.synonyms, query),
        );
        if (subcategory) {
          return subcategory.subcategoryId;
        }
      }
    }
  }
  return null;
};

const getCategoryIdFromSynonyms = (synonyms, query) => {
  if (!synonyms) return false;
  for (let i = 0; i < synonyms.length; i++) {
    const synonym = synonyms[i].toLowerCase().includes(query);
    if (synonym) return true;
  }
};

/**
 * Check if product has minimum amount or order unit
 * If so set the minimum quantity accordingly
 * @param minAmount Product minimum amount
 * @param orderUnit Product order unit
 * @param quantityCount The selected quantity
 * @returns {number|number|*}
 */
export const checkMinAmountAndOrderUnit = (
  minAmount,
  orderUnit,
  quantityCount,
) => {
  let decrementOrderUnit = !!orderUnit ? orderUnit : 1;
  let decrementMinAmount = !!minAmount ? minAmount : 1;
  // If orderUnit is bigger than minAmount, set minAmount equal to orderUnit
  // so we can use one formula
  if (decrementOrderUnit > 1 && decrementMinAmount < decrementOrderUnit) {
    decrementMinAmount = decrementOrderUnit;
  }
  return quantityCount - orderUnit > decrementMinAmount
    ? quantityCount - decrementOrderUnit
    : decrementMinAmount;
};

/**
 * Filter cart items depending on the product. Used in cart reducer.
 * @param cartItems
 * @param product
 * @returns {*}
 */
export const filterProduct = (cartItems, product) => {
  if (product.variation && !product.type) {
    return cartItems.filter(
      (item) =>
        item.id === product.id &&
        item.variation &&
        product.variation.weight &&
        item.variation.weight === product.variation.weight,
    )[0];
  } else if (product.type && !product.variation) {
    return cartItems.filter(
      (item) =>
        item.id === product.id && !!item.type && item.type === product.type,
    )[0];
  } else if (product.variation && product.type) {
    return cartItems.filter(
      (item) =>
        item.id === product.id &&
        item.variation &&
        item.type &&
        item.variation.weight === product.variation.weight &&
        item.type === product.type,
    )[0];
  } else {
    return cartItems.filter((item) => item.id === product.id)[0];
  }
};

/**
 * Find purchased product in CMS products to find the current variant of the product
 * (Product properties might have changed)
 * If there are changes in the purchased product and cms product, it will return nothing
 * @param productToFind
 * @param productList
 * @returns {*}
 */
export const findProduct = (productToFind, productList) => {
  return productList.find(
    (product) => product.id === productToFind.productId && product.stock > 0,
  );
};

/**
 * Check if product has variation
 * @param cmsProduct
 * @param orderProduct
 * @returns {boolean}
 */
export const hasProductVariation = (cmsProduct, orderProduct) => {
  if (!!orderProduct.variation && orderProduct.variation.weight) {
    return (
      cmsProduct.variations.filter(
        (variation) =>
          variation.weight === orderProduct.variation.weight &&
          variation.price === orderProduct.variation.price,
      ).length > 0
    );
  }
};

export const isProductVisible = (product, collections, groupId) => {
  const productCollection = getProductCollection(product, collections);
  return (
    !product.hideVariant &&
    isProductVisibleForGroup(product, groupId) &&
    isCollectionVisibleForGroup(productCollection, groupId)
  );
};

export const filteredAllHiddenProducts = (products, collections, groupId) => {
  return products.filter((product) => {
    return isProductVisible(product, collections, groupId);
  });
};

/**
 * Filter product by the hide variant property, used in Shoplist
 * @param products
 * @returns products filtered by the hideVariant property
 */
export const filterHiddenProducts = (products) => {
  return products.filter((product) => !product.hideVariant);
};

/**
 * Filter products based on their group and whether product/collection is visible for group
 * @param products
 * @param collections
 * @param groupId of user
 * @returns products filtered
 */
export const filterHiddenProductsForGroupAndCollection = (
  products,
  collections,
  groupId,
) => {
  return products.filter((product) => {
    const productCollection = getProductCollection(product, collections);
    return (
      isProductVisibleForGroup(product, groupId) &&
      isCollectionVisibleForGroup(productCollection, groupId)
    );
  });
};

export const filterProductsByPriceRange = (
  products,
  priceRange,
  groupId,
  groupSettingsUserGroup,
  selectedSalesGroup,
) => {
  if (
    !!priceRange &&
    priceRange.length === 2 &&
    !_.isEqual(priceRange, [0, 0])
  ) {
    return products.filter((product) => {
      const price = getPaymentPriceForGroup(
        product,
        groupId,
        groupSettingsUserGroup,
        selectedSalesGroup,
      );
      const min = priceRange[0];
      const max = priceRange[1];
      return price >= min && price <= max;
    });
  } else return products;
};

export const filterProductsByColors = (products, colors) => {
  if (!!colors && colors.length > 0) {
    return products.filter((product) => colors.includes(product.color));
  } else return products;
};

export const filterProductsByHeights = (products, heights) => {
  if (!!heights && heights.length > 0) {
    return products.filter((product) =>
      heights.some((item) => _.inRange(product.height, item[0], item[1])),
    );
  } else return products;
};

export const filterProductsByDiameters = (products, diameters) => {
  if (!!diameters && diameters.length > 0) {
    return products.filter((product) =>
      diameters.some((item) => _.inRange(product.diameter, item[0], item[1])),
    );
  } else return products;
};

/**
 * Get the initial amount for the cart increaser based on minAmount and orderUnit of product
 * @param minAmount
 * @param orderUnit
 */
export const getInitialCartAmount = (minAmount, orderUnit) => {
  return orderUnit > minAmount ? orderUnit : minAmount;
};

/**
 * Check whether product is available for pre-order
 * @param preOrderObject
 * @param stock
 * @param orderUnit
 * @returns Whether product is a pre-order product
 */
export const isPreOrderProduct = (preOrderObject, stock, orderUnit) => {
  const today = moment();
  const orderUnitAvailable = orderUnit ? orderUnit : 1;
  if (stock >= orderUnitAvailable) return false;
  return (
    !!preOrderObject &&
    preOrderObject.active &&
    moment(preOrderObject.availableAt, "DD-MM-YYYY").isAfter(today)
  );
};

export const isProductVisibleForGroup = (product, groupId) => {
  let groupAlternatives =
    !!product && !!product.groupAlternatives ? product.groupAlternatives : null;
  if (!!groupAlternatives) {
    let alternativeForGroup = _.find(
      groupAlternatives,
      (item) => item.groupId === groupId,
    );
    if (!!alternativeForGroup) return alternativeForGroup.visible;
    else return true;
  } else return true;
};

export const getPriceRange = (
  products,
  groupId,
  groupSettingsUserGroup,
  selectedSalesGroup,
) => {
  if (!!products) {
    const lowest = getPaymentAndDiscountPriceForGroup(
      _.minBy(products, (product) => {
        let price = getPaymentAndDiscountPriceForGroup(product, groupId);
        return !!price.discounted ? price.discounted : price.original;
      }),
      groupId,
      groupSettingsUserGroup,
      selectedSalesGroup,
    );
    const highest = getPaymentAndDiscountPriceForGroup(
      _.maxBy(products, (product) => {
        let price = getPaymentAndDiscountPriceForGroup(product, groupId);
        return !!price.discounted ? price.discounted : price.original;
      }),
      groupId,
      groupSettingsUserGroup,
      selectedSalesGroup,
    );
    return [
      Math.floor(lowest.discounted || lowest.original),
      Math.ceil(highest.discounted || highest.original),
    ];
  } else return [0, 0];
};

export const getColorsForProducts = (products) => {
  let array = [];
  products.forEach((product) => {
    let foundColor = PRODUCT_COLORS.find((item) => item.id === product.color);
    if (!!foundColor && !array.includes(foundColor)) array.push(foundColor);
  });
  return array;
};

export const getDimensionsForProducts = (products) => {
  let heights = [];
  let diameters = [];
  products.forEach((product) => {
    let foundHeight = PRODUCT_HEIGHTS.find((item) =>
      _.inRange(product.height, item[0], item[1]),
    );
    let foundDiameter = PRODUCT_DIAMETERS.find((item) =>
      _.inRange(product.diameter, item[0], item[1]),
    );
    if (!!foundHeight && !heights.includes(foundHeight))
      heights.push(foundHeight);
    if (!!foundDiameter && !diameters.includes(foundDiameter))
      diameters.push(foundDiameter);
  });
  const sortedHeights = _.sortBy(heights, (item) => {
    return item[0];
  });
  const sortedDiameters = _.sortBy(diameters, (item) => {
    return item[0];
  });
  return { heights: sortedHeights, diameters: sortedDiameters };
};

export const productHasDimensions = (product) => {
  if (!!product) {
    if (!!product.height) return true;
    else if (!!product.diameter) return true;
    else if (!!product.length) return true;
    else if (!!product.width) return true;
    else return false;
  } else return false;
};

export const getProductById = (products, productId) => {
  if (!!products && !!productId) {
    return _.find(products, (item) => item.id === productId);
  } else return null;
};

/**
 * Check if product has minimum amount
 * If so set the minimum quantity to that amount
 * @param minAmount Product minimum amount
 * @param quantityCount The selected quantity
 * @returns {number|number|*}
 */
export const checkMinAmount = (minAmount, quantityCount) => {
  if (minAmount) {
    return quantityCount > minAmount ? quantityCount - 1 : minAmount;
  } else {
    return quantityCount > 1 ? quantityCount - 1 : 1;
  }
};

export const getProductsByCode = (products, codes, groupId) => {
  const list = [];
  if (!!codes && !!products) {
    codes.forEach((code) => {
      const product = products.find((p) => !!p.code && p.code === code);
      if (!!product && isProductVisibleForGroup(product, groupId))
        list.push(products.find((p) => !!p.code && p.code === code));
    });
  }
  return list;
};

export const sortProductsByNew = (products) => {
  return _.orderBy(products, ["createdAt"], ["desc"]);
};

// Zet de producten zonder voorraad achteraan
export const sortProductsByStock = (products) => {
  const productsWithStock = products.filter(
    (product) =>
      product.stock > (product.orderUnit || 1) ||
      isPreOrderProduct(product.pre_order, product.stock, product.orderUnit),
  );
  const productsWithoutStock = products.filter(
    (product) =>
      product.stock < (product.orderUnit || 1) &&
      !isPreOrderProduct(product.pre_order, product.stock, product.orderUnit),
  );
  return [...productsWithStock, ...productsWithoutStock];
};

export const removePreOrderProducts = (products) => {
  return _.filter(
    products,
    (p) => !isPreOrderProduct(p.pre_order, p.stock, p.orderUnit),
  );
};
